<template>
  <div class="login">
    <form @submit.prevent="login">
      <UIInput
        label="Email"
        type="email"
        name="email"
        autocomplete="email"
        editable
        required
      />
      <UIInput
        label="Пароль"
        type="password"
        name="password"
        autocomplete="current-password"
        editable
        required
      />
      <UIButton class="w-100" text="Вход" type="submit" />
    </form>
  </div>
</template>

<script>
import UIInput from "@/components/UI/Input";
import UIButton from "@/components/UI/Button";

export default {
  name: "Login",
  components: { UIInput, UIButton },
  methods: {
    login(e) {
      const formData = new FormData(e.target);
      this.$store
        .dispatch("login", Object.fromEntries(formData))
        .then(() => {
          if (this.$route.query.redirect) {
            return this.$router.push(`${this.$route.query.redirect}`);
          } else {
            return this.$router.push({ name: "DashboardHome" });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            this.$notify({
              type: "error",
              text: error.response.data["message"],
            });
          } else {
            this.$notify({
              type: "error",
              text: "Неизвестная ошибка",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: var(--text-second);
}
</style>
